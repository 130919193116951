<template>
  <div class="pay">
    <div class="in">
      <img v-if="!logoerror" class="patient-icon" :src="`${logo}`"/>
      <div v-if="logoerror" style="margin: 15px 0">欢迎使用移动影像服务</div>
      <div class="container">
        <div class="main">
          <p>您的检查影像以数字影像的方式保存在互联网</p>
          <template  v-if="goods.arr.length == 1" >
            <p v-for="item in goods.arr" :key="item.goods_id" >保存期限为: 10年</p>
          </template>
          <p>请选购智能数字胶片服务（仅用于本次检查）</p>
          <div style="text-align: center; margin: 10px 0;">
            <el-radio v-model="goods.selected" v-for="item in goods.arr" :key="item.goods_id" :label="item.goods_id" border>
              <p class="radio-text" >{{item.service_duration}}</p>
              <p class="radio-text b">¥ {{item.goods_amount}} 元</p>
            </el-radio>
          </div>
        </div>
        <div class="info">
          <p class="title">数字影像的优势：</p>
          <ul>
            <li>就诊更方便  不需要携带胶片，只要保存二维码，随时查询检查数据和诊断报告，方便复诊、转诊。</li>
            <li>诊断更精准  数字影像提供完整扫描数据，比传统胶片更精准。</li>
            <li>分享更方便  可通过微信进行分享，咨询专家。</li>
            <li>应用更环保  无污染、绿色环保。</li>
          </ul>
        </div>
      </div>
      <div style="padding-top: 10px; text-align: left;">
        <el-checkbox v-model="checked">我已阅读并同意</el-checkbox><el-link  @click="protocol = true" style="margin-top: -3px;" type="primary">《医疗影像服务协议》</el-link>
      </div>
      <div style="padding-top: 10px;">
        <el-button style="width: 100%" type="primary" @click="pay" :disabled="!checked">微信支付</el-button>
      </div>
    </div>
    <div class="protocol" v-if="protocol">
      <div class="text" v-html="goods.protocol"></div>
      <!-- <iframe class="iframe" width="100%" height="100%" src="/数字影像浏览服务协议.xml"></iframe> -->
      <el-button @click="protocol = false" class="close" type="primary" size="small" round>返 回</el-button>
    </div>
  </div>
</template>

<script>
import { store } from '../../utils/store'
export default {
  name: 'pay',
  components: {
  },
  props: {
    goods: {
      type: Object,
      require: true
    }
  },
  data() {
    return {
      checked: false,
      protocol: false,
      logo: '',
      logoerror: false,
    }
  },
  created() {
  },
  mounted() {
    var self = this
    this.logo = `${store.imageUrl}logo_${store.hospital.shortName}.png`
    var logondom = document.createElement("img");
    logondom.src = this.logo
    logondom.onerror=function(){
      self.logoerror = true
    }
  },
  methods: {
    pay () {
      this.$emit("payFN", this.goods.arr.filter(d=> {return d.goods_id === this.goods.selected})[0]);
    }
  }
}
</script>

<style scoped>
  .patient-icon{
    max-width: 80%;
    max-height: 50px;
    margin-top: 15px
  }
  .container{
    text-align: left;
    max-height: calc(100% - 140px);
    overflow: auto;
  }
  .main{

  }
  .main>p{
    margin: 0;
    line-height: 2
  }
  .info{
    margin: 0;
    font-size: 0.9em
  }
  .info .title{
    font-weight: 600;
    margin: 0;
  }
  .info ul{
    text-align: left;
    line-height: 2;
    padding: 0;
    padding-left: 15px;
    margin: 10px 0 0;
  }
  >>> .el-radio__input{
    display: none
  }
  >>> .el-radio__label{
    display: block;
    padding: 0
  }
  >>> .el-radio.is-bordered{
    height: auto
  }
  >>> .el-radio.is-bordered{
    padding: 0px 30px;
  }
  .b{
    font-weight: bold
  }
  .radio-text{
    margin: 10px 0;
  }
  .pay {
    padding: 15px;
    text-align: center;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: rgba(0,0,0,0.1);
  }
  .pay .in{
    height: calc(100% - 20px);
    background: #fff;
    border-radius: 10px;
    padding: 10px;
  }
  .protocol{
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    background: #fff;
    text-align: left;
    padding: 5px 10px;
  }
  .protocol .iframe{
    width: 100%;
    height: 100%;
    border: none;
  }
  .protocol .close{
    width: 100%;
    margin-top: 5px;
  }
  .protocol .text{
    height: calc(100% - 50px);
    overflow: auto;
  }
  >>> .protocol .p{
    margin: 5px;
    /* text-indent:1em */
  }
  >>> .protocol .p:first-of-type{
    text-align: center;
    font-size: 16px
  }
</style>