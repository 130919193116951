<template>
    <div class="share-view-container">
        <div class="period-box">
            <div class="period-prompt">分享码有效期</div>
            <el-radio-group v-model="period" v-on:change="changeToken">
                <el-radio label="6h">6 小时</el-radio>
                <el-radio label="1d">1 天</el-radio>
                <el-radio label="3d">3 天</el-radio>
                <el-radio label="7d">7 天</el-radio>
            </el-radio-group>
        </div>
        <div class="qr-box">
            <div class="qr-prompt">手机扫一扫下面二维码查看此检查报告和图像</div>
            <div>
                <vue-qr :text="qrText" />
            </div>
        </div>
        <div class="share-code-box">
            <div>在影像工作站中输入下面数字</div>
            <div>可查看此检查报告和图像</div>
            <div>
                <div class="share-code">{{shareCode}}</div>
            </div>
        </div>
        <div class="buttons-box">
            <el-button class="copy-button" @click="copyUrl">复制链接</el-button>
        </div>
    </div>
</template>

<script>
import VueQr from 'vue-qr'
import {MessageProxy} from '../../utils/MessageProxy'
import { store } from '../../utils/store'
// import { Config } from '../../utils/Utils'
export default {
    name: 'ShareCodeView',
    components: {
        VueQr
    },
    props: {
        risStudyId: String,
        hospitalId: String
    },
    data() {
        return {
            qrText: '',
            shareCode: "",
            period: "6h"
        }
    },
    created() {
        this.getToken();
        this.getAuthCode();
    },
    methods: {
        changeToken() {
            this.getToken();
            this.getAuthCode();
        },
        getToken() {
            MessageProxy.getToken(store.userId, this.risStudyId, this.period).then((result)=>{
                if (true == result.hasError) {
                    this.$message.error(result.errorText);
                    return;
                }
                if (result.data != null && result.data.code != '200') {
                    this.$message.error(result.data.message);
                    return;
                }
                if (result.data.data != undefined && 
                    result.data.data.token != undefined &&
                    result.data.data.token != "") {
                    this.qrText = location.href;
                    //`${location.origin}${location.pathname}#/?${Config.getUrlParam().str}${Config.getUrlParam().str ? '&' : ''}token=${result.data.data.token}`
                } else {
                    this.$message.error('获取令牌失败。');
                }
            })
        },
        getAuthCode() {
            MessageProxy.getAuthorizationCode(store.userId, this.risStudyId, this.period, this.hospitalId).then((result)=>{
                if (true == result.hasError) {
                    this.$message.error(result.errorText);
                    return;
                }
                if (result.data != null && result.data.code != '200') {
                    this.$message.error(result.data.message);
                    return;
                }
                if (result.data.data != undefined && 
                    result.data.data.authcode != undefined &&
                    result.data.data.authcode != "") {
                    this.shareCode = result.data.data.authcode;
                } else {
                    this.$message.error('获取共享码失败。');
                }
            })
        },
        copyUrl() {
            let input=document.createElement('input');
            input.setAttribute('readonly','readonly');
            // input.setAttribute('value', store.actualUrl + "?studyid=" + this.risStudyId);
            input.setAttribute('value', this.qrText);
            document.body.appendChild(input);
            input.setSelectionRange(0,999);
            input.select();
            if(document.execCommand) {
                document.execCommand('copy');
                this.$message.success("复制成功。");
            }
            document.body.removeChild(input);
        }
    }
}
</script>

<style scoped>
    .share-view-container {
        position: relative;
        height: 100%;
        overflow-y: auto;
    }

    .share-view-container .period-box,
    .share-view-container .qr-box,
    .share-view-container .share-code-box {
        background-color: #fff;
        border-radius: 3vmin;
        margin: 0 5vmin 5vmin 5vmin;
        padding: 5vmin 0;
        font-size: 3.5vmin;
        text-align: center;
    }

    .share-view-container .period-box {
        font-size: 4vmin;
    }
    .share-view-container .period-prompt {
        margin-bottom: 3vmin;
    }

    .share-view-container .share-code {
        font-size: 15vmin;
    }
    .share-view-container .buttons-box {
        margin: 0 5vmin 5vmin 5vmin;
    }
    .share-view-container .copy-button {
        box-sizing: border-box;
        width: 100%;
    }
</style>